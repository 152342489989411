import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('admin/manage-role?page=' + page, {params});
    }, 
    show(id) {
        return Service().get('admin/manage-role/show/'+id);
    },    
    store(params) {
        return Service().post('admin/manage-role/store', params);
    },
    update(id, params) {
        return Service().post('admin/manage-role/update/'+id, params);
    },
    delete(id) {
        return Service().post('admin/manage-role/delete/'+id);
    }
}